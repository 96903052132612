import "./App.css";
import SMSForm from "./SMSForm";
import logo from "./logo.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" />
        <p>Enviar Questionários</p>
        <SMSForm />
      </header>
    </div>
  );
}

export default App;
