import React, { Component } from "react";
import "./SMSForm.css";
import * as XLSX from "xlsx";

const chronicAnxietyLevelsSurveyId = "311661244";
const tolerabilitySurveyId = "315642093";
const hypertensionSignUpForDoctorsSurveyId = "315535988";
const stopBangSurveyId = "315742301";
const diabetesRiskSurveyId = "315699897";
const ampaSurveyId = "311606399";
const qualityOfLifeId = "309202405";
const healthLiteracySurveyId = "311602143";
const cardiovascularRiskSurveyId = "311653754";
const medicationIntakeSurveyId = "316734108";
const npsChsfSurveyId = "316781984";

class SMSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        to: "",
        body: "",
        tenant: "",
        survey_id: "",
        email: "",
        contactList: [],
      },
      submitting: false,
      error: false,
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleFileAsync = this.onHandleFileAsync.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onHandleChange(event) {
    const name = event.target.getAttribute("name");
    this.setState({
      message: { ...this.state.message, [name]: event.target.value },
    });
  }

  async onHandleFileAsync(e) {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);
    this.setState({
      message: { ...this.state.message, contactList: jsonData },
    });
    console.log(this.state.message);
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ submitting: true });
    console.log(JSON.stringify(this.state.message));
    fetch("/api/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.message),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.setState({
            error: false,
            submitting: false,
            message: {
              ...this.state.message,
              to: "",
              body: "",
              tenant: "",
              survey_id: "",
              email: "",
            },
          });
        } else {
          this.setState({
            error: true,
            submitting: false,
          });
        }
      });
  }

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        className={this.state.error ? "error sms-form" : "sms-form"}
      >
        <div>
          <label>Upload CSV</label>
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={this.onHandleFileAsync}
          />
        </div>
        {/* <div>
          <label htmlFor="to">To:</label>
          <input
            type="tel"
            name="to"
            id="to"
            value={this.state.message.to}
            onChange={this.onHandleChange}
          />
        </div> */}
        {/* <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            value={this.state.message.email}
            onChange={this.onHandleChange}
          />
        </div> */}
        <div>
          <label htmlFor="body">Body:</label>
          <textarea
            name="body"
            id="body"
            value={this.state.message.body}
            onChange={this.onHandleChange}
          />
        </div>
        <label>Tenant</label>
        <select
          name="tenant"
          id="tenant"
          onChange={this.onHandleChange}
          value={this.state.message.tenant}
        >
          <option value="">Select option</option>
          <option value="chsf">CHSF</option>
          <option value="knok_staging">Knok Staging</option>
        </select>
        <label>Questionnaire</label>
        <select
          name="survey_id"
          id="survey_id"
          onChange={this.onHandleChange}
          value={this.state.message.survey_id}
        >
          <option value="">Select option</option>
          <option value={ampaSurveyId}>AMPA</option>
          <option value={chronicAnxietyLevelsSurveyId}>Anxiety</option>
          <option value={diabetesRiskSurveyId}>Diabetes</option>
          <option value={cardiovascularRiskSurveyId}>Cardiovascular</option>
          <option value={healthLiteracySurveyId}>Health Literacy</option>
          <option value={tolerabilitySurveyId}>Tolerability</option>
          <option value={stopBangSurveyId}>Stop Bang</option>
          <option value={qualityOfLifeId}>Quality of Life</option>
          <option value={medicationIntakeSurveyId}>Medication Intake</option>
          <option value={npsChsfSurveyId}>NPS CHSF</option>
        </select>
        <button type="submit" disabled={this.state.submitting}>
          Enviar Mensagem
        </button>
      </form>
    );
  }
}

export default SMSForm;
